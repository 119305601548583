form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  font-size: 1em;
  font-family: 'Raleway', sans-serif;
  color: #eaeceb;
}

form input {
  margin-top: 0.2em;
  width: 100%;
  height: 1em;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 2px solid #eaeceb;
  background-color: #eaeceb64;
  font-family: 'Open Sans', sans-serif;
  color: #eaeceb;
}

form input:focus {
  border: 2px solid #6ea6c9;
}

form textarea {
  margin-top: 0.2em;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;

  border: 2px solid #eaeceb;
  background-color: #eaeceb64;
  font-family: 'Open Sans', sans-serif;
  color: #eaeceb;
}

form textarea:focus {
  border: 2px solid #6ea6c9;
}

form label {
  margin-top: 1rem;
}

form input[type='submit'] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  cursor: pointer;
  background: #6ea6c9;
  border: none;
  border-radius: 8px;
  height: 2em;
  width: 8em;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: 600;
  color: #eaeceb;
}

.sent {
  margin-bottom: 5em;
}

@media (max-width: 900px) {
  form {
    width: 100%;
  }
  form input[type='submit'] {
    font-size: 0.7em;
  }
}
