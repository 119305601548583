.nav-bar {
  position: fixed;
  height: 100%;
  width: 10.4%;
  border-right: solid 2px #eaeceb;
  top: 0;
  z-index: 3;
  min-height: 500px;
  box-sizing: border-box;
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: 500;
  z-index: 7;
}

.nav-bar nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.nav-bar nav a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #eaeceb;
  top: 5em;
  margin-bottom: 2.5em;
}

.nav-bar nav a.active {
  color: #eaeceb;
}

.nav-link {
  width: 2em;
  height: 5em;
  fill: #eaeceb;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #394358;
  position: relative;
}

.nav-icon {
  position: absolute;

  left: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.nav-bar nav a:hover .nav-icon {
  opacity: 1;
}

.nav-bar nav a span {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.nav-bar nav a:hover span {
  opacity: 0;
}

svg {
  width: 40px;
  height: 40px;
}
.nav-api-text {
  font-size: 12px;
}

@media (max-width: 768px) {
  .nav-bar {
    display: none;
  }
}
