.page {
  margin-left: 10.4%;
  margin-top: 5%;
  box-sizing: border-box;
}

.page h1 {
  font-family: 'Raleway', sans-serif;
  color: #eaeceb;
  display: flex-end;
  align-items: center;
}

@media (max-width: 900px) {
  .page {
    margin-left: 0;
    margin-top: 15%;
  }
}
