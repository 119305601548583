.home-page {
  background-color: #161616;
  padding-top: 6%;
  padding-left: 10.4%;
  padding-right: 12%;
}
.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'title photo'
    'tech-stack photo';
}

.grid-title {
  margin: 0;
  font-size: 5em;
  font-family: 'Raleway light', sans-serif;
  font-weight: 700;
  color: #eaeceb;
  grid-area: title;
}
.grid-tech-stack {
  max-width: 80%;
  grid-area: tech-stack;
}
.grid-tech-stack h3 {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75em;
  color: #eaeceb;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.tech-word {
  border: solid 1px white;
  padding: 5px 10px;
  border-radius: 15px;
  margin: 5px 5px;
}
.grid-personal-photo {
  overflow: hidden;
  grid-area: photo;
}
.img-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center center;
}
.bottom-text {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  font-size: 2.5em;
  font-family: 'Raleway light', sans-serif;
  color: #eaeceb;
}
.accordion {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom-text-small {
  font-size: 12px;
}

.contact-button {
  margin-top: 1em;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: 600;
  margin-right: 1em;
  color: #eaeceb;
  background-color: #6ea6c9;
  border: 2px solid #6ea6c9;
  border-radius: 8px;
  padding: 12px 12px;
  &:hover {
    cursor: pointer;
    background-color: #2c5974;
    -webkit-box-shadow: 8px 8px 28px -4px rgba(110, 166, 201, 0.295);
    -moz-box-shadow: 8px 8px 28px -4px rgba(110, 166, 201, 0.295);
    box-shadow: 8px 8px 28px -4px rgba(110, 166, 201, 0.295);
  }
}
.github-button {
  margin-top: 1em;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: 600;
  margin-right: 10px;
  color: #6ea6c9;
  background-color: transparent;
  border: 2px solid #6ea6c9;
  border-radius: 8px;
  padding: 12px 12px;
  &:hover {
    cursor: pointer;
    border: 2px solid #2c5974;
    -webkit-box-shadow: 8px 8px 28px -4px rgba(110, 166, 201, 0.295);
    -moz-box-shadow: 8px 8px 28px -4px rgba(110, 166, 201, 0.295);
    box-shadow: 8px 8px 28px -4px rgba(110, 166, 201, 0.295);
  }
}
.bottom-text-small a {
  color: #aeb9cd;
}

@media (max-width: 750px) {
  .grid-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'title photo'
      'tech-stack tech-stack';
  }

  .grid-title {
    font-size: 2.5em;
  }
  .grid-tech-stack {
    max-width: 100%;
    grid-area: tech-stack;
  }
  .grid-personal-photo {
    overflow: hidden;
    grid-area: photo;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
  }
  .contact-button {
    font-size: 0.7em;
  }
  .github-button {
    font-size: 0.7em;
  }
  .bottom-text {
    font-size: 1.25em;
  }
}
