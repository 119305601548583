.header {
  position: fixed;
  top: 0;
  height: 8.8%;
  width: 100%;
  border-bottom: 2px solid #eaeceb;
  background-color: #161616;
  padding: 0 10.4%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 6;
}
.header-text h1 {
  font-family: 'Raleway light', sans-serif;
  color: #eaeceb;
  font-size: 1.5em;
}
.header-text h1 span {
  font-family: 'Raleway light', sans-serif;
  color: #eaeceb;
  font-size: 0.7em;
}
@media (max-width: 900px) {
  .header-text h1 {
    font-family: 'Raleway light', sans-serif;
    color: #eaeceb;
    font-size: 1em;
  }
  .header-text h1 span {
    font-family: 'Raleway light', sans-serif;
    color: #eaeceb;
    font-size: 0.85em;
  }
}
